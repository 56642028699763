<template>
  <v-container fluid>
    <!-- Header -->
    <v-row>
      <v-col>
        <h6 class="text-h6">{{ $trans("booking_reminders_headline") }}</h6>
      </v-col>
    </v-row>

    <!-- Information Message -->
    <v-row>
      <v-col>
        <calendesk-information-message>
          {{ $trans("booking_reminders_info") }}
        </calendesk-information-message>
      </v-col>
    </v-row>

    <!-- Loading Indicator -->
    <template v-if="isInitialLoading">
      <div class="d-flex align-center justify-center my-2">
        <v-progress-linear height="2" color="primary" indeterminate />
      </div>
    </template>

    <!-- Content -->
    <!-- Legacy Settings -->
    <v-row v-if="showLegacyRemindersSettings">
      <v-col
        v-if="!wantsToSetDifferentSendingTimes"
        cols="6"
        md="3"
        class="d-flex align-center pb-0 mb-0"
      >
        <v-select
          v-model="bookingsReminderTimeBefore"
          :disabled="isLoading"
          outlined
          hide-details
          :items="getDurationList"
          :item-text="renderDurationFromObject"
          item-value="duration"
          :label="$trans('booking_reminder_setting_info')"
          :rules="[rules.required]"
          persistent-hint
        />
      </v-col>
      <v-col cols="12" class="pt-0 mt-0">
        <v-checkbox
          v-model="wantsToSetDifferentSendingTimes"
          :label="$trans('booking_reminders_enable_custom_reminder_checkbox')"
          hide-details
          @change="wantsToSetDifferentSendingTimesHandler"
        />
      </v-col>
    </v-row>

    <!-- Save Button -->
    <v-row v-if="!wantsToSetDifferentSendingTimes">
      <v-col>
        <v-btn
          :disabled="isLoading"
          :loading="isLoading"
          color="primary"
          depressed
          @click="saveCurrentBookingRemindersSettings"
        >
          {{ $trans("save") }}
        </v-btn>
      </v-col>
    </v-row>

    <!-- Custom Reminders Section -->
    <template v-if="wantsToSetDifferentSendingTimes">
      <!-- Existing Reminders -->
      <v-row>
        <v-col cols="12" class="mt-4">
          <calendesk-information-message
            v-if="!bookingReminders || bookingReminders.length === 0"
          >
            {{ $trans("booking_reminders_no_reminders_info_label") }}
          </calendesk-information-message>
          <h6 v-else class="text-h6">
            {{ $trans("booking_reminders_headline_2") }}:
          </h6>
        </v-col>

        <v-col
          v-for="(bookingReminder, index) in bookingReminders"
          :key="index"
          cols="12"
          class="d-flex align-center flex-wrap mb-2"
        >
          <v-text-field
            hide-details
            dense
            outlined
            :value="
              $trans(
                'booking_reminders_recipient_type_' +
                  bookingReminder.recipient_type,
              )
            "
            :label="$trans('booking_reminders_element_receiver_label')"
            readonly
            disabled
            class="mr-2 mb-2 reminder-input"
          />
          <v-text-field
            hide-details
            dense
            outlined
            :value="
              $trans(
                'booking_reminders_notification_type_' +
                  bookingReminder.notification_type,
              )
            "
            :label="$trans('booking_reminders_element_type_label')"
            readonly
            disabled
            class="mr-2 mb-2 reminder-input"
          />
          <v-text-field
            hide-details
            dense
            outlined
            :value="
              renderDurationFromNumber(bookingReminder.time_before_booking)
            "
            :label="$trans('booking_reminder_setting_info')"
            readonly
            disabled
            class="mr-2 mb-2 reminder-input"
          />
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                color="light"
                icon
                @click.native="removeBookingReminder(bookingReminder)"
                v-on="on"
              >
                <v-icon>$trash-default</v-icon>
              </v-btn>
            </template>
            {{ $trans("delete") }}
          </v-tooltip>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-btn
                color="light"
                icon
                @click.native="duplicateBookingReminder(bookingReminder)"
                v-on="on"
              >
                <v-icon color="light">$copy</v-icon>
              </v-btn>
            </template>
            {{ $trans("duplicate") }}
          </v-tooltip>
        </v-col>
      </v-row>

      <!-- New Reminders -->
      <template v-if="hasBookingRemindersToAdd">
        <v-row>
          <v-form ref="newBookingRemindersToAddForm">
            <v-col
              v-for="row in bookingRowsToAdd"
              :key="row.internalId"
              class="d-flex align-center flex-wrap mb-2"
              cols="12"
            >
              <v-select
                v-model="row.recipientType"
                class="mr-2 mb-2 reminder-input"
                dense
                :disabled="isLoading"
                :rules="[rules.required]"
                hide-details="auto"
                outlined
                :items="getBookingReminderRecipientItems"
                :label="$trans('booking_reminders_element_receiver_label')"
              />
              <v-select
                v-model="row.notificationType"
                class="mr-2 mb-2 reminder-input"
                dense
                :disabled="isLoading"
                :rules="[rules.required]"
                hide-details="auto"
                outlined
                :items="getBookingReminderNotificationTypeItems"
                :label="$trans('booking_reminders_element_type_label')"
              />
              <v-select
                v-model="row.timeBeforeBooking"
                class="mr-2 mb-2 reminder-input"
                dense
                :disabled="isLoading"
                :rules="[rules.required]"
                hide-details="auto"
                outlined
                :items="getDurationList"
                :item-text="renderDurationFromObject"
                item-value="duration"
                :label="$trans('booking_reminder_setting_info')"
              />
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    color="light"
                    icon
                    @click.native="removeObjectFromBookingRowsToAdd(row)"
                    v-on="on"
                  >
                    <v-icon>$trash-default</v-icon>
                  </v-btn>
                </template>
                {{ $trans("delete") }}
              </v-tooltip>
              <v-tooltip bottom>
                <template #activator="{ on }">
                  <v-btn
                    color="light"
                    icon
                    @click.native="duplicateObjectFromBookingRowsToAdd(row)"
                    v-on="on"
                  >
                    <v-icon color="light">$copy</v-icon>
                  </v-btn>
                </template>
                {{ $trans("duplicate") }}
              </v-tooltip>
            </v-col>
          </v-form>
        </v-row>

        <!-- Add Another Button -->
        <v-row>
          <v-col>
            <v-btn
              text
              outlined
              color="secondary"
              @click.native="addObjectToBookingRowsToAdd"
            >
              <div class="d-flex align-center">
                <v-icon left>$playlist-plus</v-icon>
                {{ $trans("booking_reminders_add_new_button") }}
              </div>
            </v-btn>
          </v-col>
        </v-row>
      </template>

      <!-- Save Button -->
      <v-row>
        <v-col class="mt-4">
          <v-btn
            v-if="hasBookingRemindersToAdd"
            :disabled="isAddingNewBookingReminder"
            :loading="isAddingNewBookingReminder"
            color="primary"
            depressed
            @click="saveNewBookingReminders"
          >
            {{ $trans("save") }}
          </v-btn>
          <v-btn
            v-else
            text
            outlined
            color="secondary"
            @click.native="addObjectToBookingRowsToAdd"
          >
            <div class="d-flex align-center">
              <v-icon left>$playlist-plus</v-icon>
              {{ $trans("booking_reminders_add_new_button") }}
            </div>
          </v-btn>
        </v-col>
      </v-row>
    </template>
  </v-container>
</template>

<script>
import Vue from "vue";
import CalendeskInformationMessage from "@/lib/calendesk-js-library/components/CalendeskInformationMessage.vue";
import bookingReminderActions from "@/calendesk/mixins/bookingReminderActions";

export default Vue.extend({
  components: {
    CalendeskInformationMessage,
  },
  mixins: [bookingReminderActions],
  created() {
    this.reloadBookingReminders();
  },
});
</script>

<style scoped lang="scss">
.reminder-input {
  width: 270px;
  max-width: 270px;
}
</style>
