import { mapActions, mapGetters } from "vuex";
import duration from "@/lib/calendesk-js-library/filters/duration";
import {
  cloneObject,
  durationList,
  generateRandomString,
} from "@/lib/calendesk-js-library/tools/helpers";
import { required } from "@/lib/calendesk-js-library/forms/validators";
import sharedConfirmDialogTypes from "@/lib/calendesk-js-library/components/confirmDialogs/sharedConfirmDialogTypes";
import sharedActions from "@/lib/calendesk-js-library/mixins/sharedActions";
import {
  errorNotification,
  successNotification,
} from "@/lib/calendesk-js-library/tools/notification";
import planActions from "@/calendesk/mixins/planActions";

export default {
  mixins: [sharedActions, planActions],
  data() {
    return {
      rules: { required },
      isLoading: false,
      isInitialLoading: false,
      wantsToSetDifferentSendingTimes: false,
      showLegacyRemindersSettings: false,
      isAddingNewBookingReminder: false,
      bookingRowsToAdd: [],
      bookingsReminderTimeBefore: 1440,
    };
  },
  computed: {
    ...mapGetters({
      adminConfiguration: "setup/getAdminConfiguration",
      bookingReminders: "bookingReminder/getAllBookingReminders",
      getRefreshBookingReminderStatus: "bookingReminder/getRefreshStatus",
    }),
    getDurationList() {
      return [
        ...durationList(1, 60, 1),
        ...durationList(60, 1440, 5),
        ...durationList(1440, 43200, 1440),
      ];
    },
    getBookingReminderNotificationTypeItems() {
      return this.mapTypeToItems(
        this.$helpers.bookingReminderNotificationTypes,
        "booking_reminders_notification_type_",
      );
    },
    getBookingReminderRecipientItems() {
      return this.mapTypeToItems(
        this.$helpers.bookingReminderRecipientTypes,
        "booking_reminders_recipient_type_",
      );
    },
    hasBookingRemindersToAdd() {
      return this.bookingRowsToAdd.length > 0;
    },
  },
  watch: {
    getRefreshBookingReminderStatus() {
      this.reloadBookingReminders();
    },
  },
  created() {
    this.bookingsReminderTimeBefore =
      this.adminConfiguration.bookings_reminder_time_before;
  },
  methods: {
    ...mapActions({
      updateConfiguration: "setup/updateConfiguration",
      fetchAllBookingReminders: "bookingReminder/fetchAllBookingReminders",
      addBookingReminder: "bookingReminder/create",
      deleteBookingReminder: "bookingReminder/delete",
      refreshBookingReminders: "bookingReminder/refreshBookingReminders",
    }),
    saveCurrentBookingRemindersSettings() {
      this.isLoading = true;
      this.updateConfiguration({
        bookings_reminder_time_before: this.bookingsReminderTimeBefore,
      })
        .then(() => {
          successNotification("update_success");
        })
        .catch((error) => {
          errorNotification("operation_failed", error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async saveNewBookingReminders() {
      if (!this.$refs.newBookingRemindersToAddForm.validate()) {
        errorNotification("form_is_invalid", {}, false);
        return;
      }

      const newReminders = this.bookingRowsToAdd.map((row) => ({
        notification_type: row.notificationType,
        time_before_booking: row.timeBeforeBooking,
        recipient_type: row.recipientType,
      }));

      this.isAddingNewBookingReminder = true;

      try {
        for (const reminder of newReminders) {
          await this.addBookingReminder(reminder);
        }

        successNotification("booking_reminders_added_successfully_label");
        this.bookingRowsToAdd = [];
      } catch (error) {
        errorNotification(null, error, false);
      } finally {
        this.reloadBookingReminders();
        this.isAddingNewBookingReminder = false;
      }
    },
    reloadBookingReminders() {
      this.isInitialLoading = true;
      this.fetchAllBookingReminders()
        .then((reminders) => {
          const hasReminders = reminders.length > 0;
          this.wantsToSetDifferentSendingTimes = hasReminders;
          this.showLegacyRemindersSettings = !hasReminders;
        })
        .finally(() => (this.isInitialLoading = false));
    },
    removeBookingReminder(bookingReminder) {
      this.openConfirmDialog({
        type: sharedConfirmDialogTypes.COMMON,
        confirmAction: async () => {
          this.setCommonDialogLoader(true);
          try {
            await this.deleteBookingReminder(bookingReminder.id);
            successNotification("delete_succeeded");
          } catch (error) {
            errorNotification(null, error, false);
          } finally {
            this.closeConfirmDialog();
            this.setCommonDialogLoader(false);
            this.refreshBookingReminders();
          }
        },
      });
    },
    addObjectToBookingRowsToAdd() {
      this.bookingRowsToAdd.push({
        internalId: generateRandomString(8),
        recipientType: null,
        notificationType: null,
        timeBeforeBooking: null,
      });
    },
    removeObjectFromBookingRowsToAdd(bookingReminder) {
      this.bookingRowsToAdd = this.bookingRowsToAdd.filter(
        (row) => row.internalId !== bookingReminder.internalId,
      );
    },
    duplicateBookingReminder(bookingReminder) {
      this.bookingRowsToAdd.push({
        internalId: generateRandomString(8),
        recipientType: bookingReminder.recipient_type,
        notificationType: bookingReminder.notification_type,
        timeBeforeBooking: bookingReminder.time_before_booking,
      });
    },
    duplicateObjectFromBookingRowsToAdd(bookingReminder) {
      const cloned = cloneObject(bookingReminder);
      cloned.internalId = generateRandomString(8);
      this.bookingRowsToAdd.push(cloned);
    },
    renderDurationFromNumber: duration,
    renderDurationFromObject(value) {
      return duration(value.duration);
    },
    mapTypeToItems(types, prefix) {
      return Object.keys(types).map((key) => ({
        text: this.$trans(prefix + types[key]),
        value: types[key],
      }));
    },
    wantsToSetDifferentSendingTimesHandler() {
      if (!this.canUseCustomBookingReminders) {
        this.$nextTick(() => {
          this.wantsToSetDifferentSendingTimes = false;
        });

        this.openFeatureNotAvailableDialog();
      }
    },
  },
};
