export default {
  NONE: 2000,
  FORGOTTEN_PASSWORD: 2001,
  RESET_PASSWORD: 2002,
  CONFIRM_SEND_MAIL: 2003,
  CONFIRM_PASSWORD_CHANGED: 2004,
  BOOKING: 2005,
  BUY_NOTIFICATION_DIALOG: 2016,
  CANCEL_PLAN_DIALOG: 2017,
  CREATE_MOBILE_DRAFT_DIALOG: 2019,
  SEND_MOBILE_APP_LINK_DIALOG: 2020,
  PUBLISH_MOBILE_DRAFT_DIALOG: 2021,
  ADD_PAYMENT_METHOD_DIALOG: 2022,
  ADD_NEW_EMPLOYEE: 2024,
  CHOOSE_TENANT: 2026,
  NOTIFICATION_TEMPLATE_EDITOR: 2027,
  NEWSLETTER_CREATE_NEW_RECORD: 2028,
  SEND_CUSTOMER_NOTIFICATION: 2029,
  DISPLAY_CUSTOMER_NOTIFICATION: 2030,
  ADD_UPDATE_SIMPLE_STORE_PRODUCT: 2032,
  HELP_CENTER: 2034,
  SUBSCRIPTION_DIALOG: 2037,
  ENABLE_ZOOM: 2038,
  CHANGE_DRAFT_NAME: 2039,
  GOOGLE_CALENDAR: 2040,
  ZOOM: 2041,
  STRIPE: 2042,
  FAKTUROWNIA: 2043,
  API_INTEGRATION: 2044,
  CALENDAR_SETTINGS: 2045,
  CREATE_USER_SUBSCRIPTION: 2046,
  USER_PAYMENT_TRANSACTIONS: 2047,
  OUTLOOK_CALENDAR: 2048,
  WHATSAPP: 2049,
  ZAPIER: 2050,
  ADD_SIMPLE_STORE_PRODUCT_PAYMENT_TRANSACTION: 2051,
  AVAILABILITY_SCHEDULE: 2052,
  MANAGE_LOCATION: 2053,
  AVAILABLE_SCHEDULE_ASSIGN_TO_EMPLOYEES: 2054,
  AVAILABLE_SCHEDULE_ASSIGN_TO_SERVICES: 2055,
};
